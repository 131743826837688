export const environment = {
    production: true,
    baseUrl: 'https://api-dev.crm.stmatech.com/v1',
    clientId: '13b22aad-a96b-4dd4-9b9b-78775a000e28',
    authority:
      'https://login.microsoftonline.com/69338af3-24c9-471e-ad0c-fc4eea039570',
    redirectUri: 'https://crm-dev.stmatech.com',
    readAllScope: 'https://api-dev.crm.stmatech.com/Members.Read.All',
    readWriteScope: 'https://api-dev.crm.stmatech.com/SupportTickets.ReadWrite.All'
  };
  